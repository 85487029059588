import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import '../styles/Login.css';

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;

function Login(props) {
  const [login] = useMutation(LOGIN);

  const [fields, setFields] = useState({
    username: '',
    password: ''
  });

  const [error, setError] = useState({
    message: null
  });

  async function onSubmit(event) {
    event.preventDefault();

    if (fields.username.length < 1) {
      setError({ message: 'Felhasználónév kötelező' });
    }
    else if (fields.password.length < 1) {
      setError({ message: 'Jelszó kötelező' });
    }
    else {
      try {
        const { data } = await login({ variables: {
          username: fields.username,
          password: fields.password
        }});
        localStorage.setItem('token', data.login.token);
        props.userChanged();
      } catch (e) {
        setError({ message: 'Hibás felhasználónév / jelszó' });
      }
    }
  };

  function handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    setFields({
      ...fields,
      [name]: target.value
    });
    setError({
      message: null
    });
  }

  return (
    <div className="login-container">
      <h2>
        🎄 Ügyfélnyilvántartó 0.7.1
      </h2>
      <form onSubmit={onSubmit}>
        <div className="login-row form-group">
          <label>Felhasználónév</label>
          <input name="username" className="form-control" onChange={handleInputChange} />
        </div>

        <div className="login-row form-group">
          <label>Jelszó</label>
          <input name="password" className="form-control" onChange={handleInputChange} type="password" />
        </div>

        {error.message &&
          <div className="alert alert-danger">
            {error.message}
          </div>
        }

        <button type="submit" className="btn btn-primary">Bejelentkezés</button>
      </form>
    </div>
  );
}

export default Login;
