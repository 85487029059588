import {
    gql
} from "@apollo/client";

const UNIT_FIELDS = `
    id,
    egyseg_nev,
    egyseg_tipus,
    egyseg_cim,
    telepules_nev,
    uzemelteto_ceg {
        nev, cim, adoszam, cegjegyzek_ev_szam, statisztikai_szam,
    },
    kapcsolattarto {
        nev, telefonszam, email, beosztas
    },
    kapcsolattarto2 {
        nev, telefonszam, email, beosztas
    },
    amt,
    megszunt,
    slow_payment,
    ellenorzesek,
    tanusitvany_szam,
    felir_szam,
    megjegyzes,
    ellenorzesi_periodus,
    hatarozott_ideju_szerzodes,
    hatarozott_ideju_szerzodes_lejarat,
    ugyfel_tipus,
    epr_egyseg {
      ktj_szam, kuj_szam, teaor_tevekenyseg, eov_x, eov_y, bevallasok_datumai
    },
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
`;

const TASK_FIELDS = `
    _id, unitId, unitName, content, assignedUsername, status, priority,
    createdBy, createdAt, updatedAt
`;

export const UNITS_QUERY = gql`
    query GetUnits {
        egysegek {
            ${UNIT_FIELDS}
        }
    }
`;

export const SINGLE_UNIT_QUERY = gql`
    query GetSingleUnit($id: Int!) {
        egyseg(id: $id) {
            ${UNIT_FIELDS}
        }
    }
`;

export const TASKS_QUERY = gql`
    query GetTasks {
      tasks {
        ${TASK_FIELDS}
      }
    }
`;

export const TASKS_FOR_UNIT_QUERY = gql`
    query GetTasksForUnit($unitId: Int!) {
      tasksFor(unitId: $unitId) {
        ${TASK_FIELDS}
      }
    }
`;


export const ADD_UNIT_MUTATION = gql`
    mutation AddUnitMutation($egyseg_nev: String!) {
        addEgyseg(egyseg_nev: $egyseg_nev)
    }
`;

export const ADD_TASK_MUTATION = gql`
    mutation AddTaskMutation($task: TaskInput!) {
        addTask(task: $task) {
            ${TASK_FIELDS}
        }
    }
`;

export const UPDATE_TASK_MUTATION = gql`
    mutation UpdateTaskMutation($taskId: String!, $task: TaskInput!) {
        updateTask(taskId: $taskId, task: $task) {
            ${TASK_FIELDS}
        }
    }
`;

export const UPDATE_UNIT_MUTATION = gql`
    mutation UpdateEgysegMutation($updateEgysegId: Int!, $egyseg: EgysegInput!) {
        updateEgyseg(id: $updateEgysegId, egyseg: $egyseg) {
            ${UNIT_FIELDS}
        }
    }
`;

export const DELETE_UNIT_MUTATION = gql`
    mutation DeleteUnitMutation($deleteEgysegId: Int!) {
        deleteEgyseg(id: $deleteEgysegId)
    }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePasswordMutation($newPassword: String!) {
    changePassword(newPassword: $newPassword)
  } 
`;
